import _objectSpread from "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import { mapGetters } from "vuex";
export default {
  name: "HistoryComponent",
  data: function data() {
    return {
      historys: [],
      activeValue: "",
      contextMenuVisible: false,
      left: 0,
      top: 0,
      isCollapse: false,
      isMobile: false,
      rightActive: ""
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters("user", ["userInfo"])), {}, {
    defaultRouter: function defaultRouter() {
      return this.userInfo.authority.defaultRouter;
    }
  }),
  created: function created() {
    var _this = this;
    this.$bus.on("mobile", function (isMobile) {
      _this.isMobile = isMobile;
    });
    this.$bus.on("collapse", function (isCollapse) {
      _this.isCollapse = isCollapse;
    });
    var initHistorys = [{
      name: this.defaultRouter,
      meta: {
        title: ''
      },
      query: {},
      params: {}
    }];
    this.historys = JSON.parse(sessionStorage.getItem("historys")) || initHistorys;
    if (!window.sessionStorage.getItem("activeValue")) {
      this.activeValue = this.$route.name + JSON.stringify(this.$route.query) + JSON.stringify(this.$route.params);
    } else {
      this.activeValue = window.sessionStorage.getItem("activeValue");
    }
    this.setTab(this.$route);
  },
  beforeDestroy: function beforeDestroy() {
    this.$bus.off("collapse");
    this.$bus.off("mobile");
  },
  methods: {
    isSame: function isSame(route1, route2) {
      if (route1.name != route2.name) {
        return false;
      }
      for (var key in route1.query) {
        if (route1.query[key] != route2.query[key]) {
          return false;
        }
      }
      for (var _key in route1.params) {
        if (route1.params[_key] != route2.params[_key]) {
          return false;
        }
      }
      return true;
    },
    setTab: function setTab(route) {
      var _this2 = this;
      if (!this.historys.some(function (item) {
        return _this2.isSame(item, route);
      })) {
        var obj = {};
        obj.name = route.name;
        obj.meta = route.meta;
        obj.query = route.query;
        obj.params = route.params;
        this.historys.push(obj);
      }
      window.sessionStorage.setItem("activeValue", this.$route.name + JSON.stringify(this.$route.query) + JSON.stringify(this.$route.params));
    }
  },
  watch: {
    $route: function $route(to, now) {
      this.historys = this.historys.filter(function (item) {
        return !item.meta.closeTab;
      });
      this.setTab(to);
      sessionStorage.setItem("historys", JSON.stringify(this.historys));
      this.activeValue = window.sessionStorage.getItem("activeValue");
      if (now && to && now.name == to.name) {
        this.$bus.$emit("reload");
      }
    }
  }
};